import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaUsers, FaPhone, FaBan, FaBriefcase, FaSignOutAlt, FaPencilAlt } from 'react-icons/fa';
import { getAuth, signOut } from 'firebase/auth'; 
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import { collection, onSnapshot, doc, setDoc, getDoc } from 'firebase/firestore'; 
import { db } from './firebase'; 
import './Sidebar.css';

const Sidebar = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [user, setUser] = useState(null);
  const [profilePicture, setProfilePicture] = useState('/default-profile.png');
  const [newApplicantsCount, setNewApplicantsCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userName = currentUser.email.split('@')[0];

      setUser({
        displayName: userName,
        email: currentUser.email,
      });

      // Fetch profile picture from Firestore
      const fetchUserProfile = async () => {
        const userDocRef = doc(db, 'Users', currentUser.email);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setProfilePicture(userData.photoURL || '/default-profile.png');
        }
      };

      fetchUserProfile();
    }

    // Real-time listener for new applicants
    const unsub = onSnapshot(collection(db, 'Applicants'), (snapshot) => {
      const newApplicants = snapshot.docs.length;
      setNewApplicantsCount(newApplicants);
    });

    return () => unsub();
  }, []);

  // Handle user logout
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/signin');
      })
      .catch((error) => {
        console.error('Logout Error:', error);
      });
  };

  // Function to handle the profile picture upload
  const handleProfilePictureUpload = async (event) => {
    const file = event.target.files[0];
    if (!file || !user) return;

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `profile-pictures/${user.email}`);

      // Upload the profile picture
      await uploadBytes(storageRef, file);

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      // Save the profile picture URL to Firestore
      const userDocRef = doc(db, 'Users', user.email);
      await setDoc(userDocRef, { photoURL: downloadURL }, { merge: true });

      // Update the state to reflect the new picture
      setProfilePicture(downloadURL);

    } catch (error) {
      console.error('Error uploading profile picture:', error);
    }
  };

  return (
    <div className="sidebar">
      {user && (
        <div className="user-profile">
          <div className="profile-picture-container">
            <img
              src={profilePicture}
              alt="Profile"
              className="profile-picture"
              onClick={() => document.getElementById('profileUpload').click()}
            />
            <FaPencilAlt className="profile-edit-icon" onClick={() => document.getElementById('profileUpload').click()} />
          </div>
          <input
            type="file"
            id="profileUpload"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleProfilePictureUpload}
          />
          <p>Hello, {user.displayName}!</p>
        </div>
      )}

      <h2>Hiring Dashboard</h2>

      <ul>
        <li>
          <Link
            to="/applicants"
            className={activeTab === '/applicants' ? 'active' : ''}
            onClick={() => setActiveTab('/applicants')}
          >
            <FaUsers style={{ marginRight: '10px' }} /> Applicants
            {newApplicantsCount > 0 && (
              <span className="notification-badge">{newApplicantsCount}</span>
            )}
          </Link>
        </li>
        <li>
          <Link
            to="/contacted"
            className={activeTab === '/contacted' ? 'active' : ''}
            onClick={() => setActiveTab('/contacted')}
          >
            <FaPhone style={{ marginRight: '10px' }} /> Contacting
          </Link>
        </li>
        <li>
          <Link
            to="/hiring"
            className={activeTab === '/hiring' ? 'active' : ''}
            onClick={() => setActiveTab('/hiring')}
          >
            <FaBriefcase style={{ marginRight: '10px' }} /> Hiring
          </Link>
        </li>
        <li>
          <Link
            to="/rejected"
            className={activeTab === '/rejected' ? 'active' : ''}
            onClick={() => setActiveTab('/rejected')}
          >
            <FaBan style={{ marginRight: '10px' }} /> Rejected
          </Link>
        </li>
      </ul>

      <div className="logout-section">
        <button className="logout-btn" onClick={handleLogout}>
          <FaSignOutAlt style={{ marginRight: '10px' }} /> Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
