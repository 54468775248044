import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase'; // Firestore instance
import './Applicants.css'; // Reuse the same styles
import { ToastContainer, toast } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

function Rejected() {
  const [rejected, setRejected] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State to track the search input
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const applicantsPerPage = 10; // Number of applicants per page

  useEffect(() => {
    // Fetch data from Firestore
    const fetchRejected = async () => {
      const querySnapshot = await getDocs(collection(db, 'Rejected'));
      const rejectedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRejected(rejectedData);
    };

    fetchRejected();
  }, []);

  // Function to handle search input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Function to move an applicant back to the Applicants collection
  const moveToApplicants = async (applicant) => {
    try {
      // Add the applicant back to the Applicants collection
      await setDoc(doc(db, 'Applicants', applicant.id), applicant);

      // Remove the applicant from the Rejected collection
      await deleteDoc(doc(db, 'Rejected', applicant.id));

      // Update the state to reflect the removal
      setRejected((prevRejected) => prevRejected.filter((item) => item.id !== applicant.id));

      // Show a success notification
      toast.success(`Moved ${applicant.firstName} ${applicant.lastName} back to Applicants!`);
    } catch (error) {
      toast.error('Failed to move applicant back to Applicants.');
    }
  };

  // Filter rejected applicants based on the search term
  const filteredApplicants = rejected.filter((applicant) => {
    const fullName = `${applicant.firstName} ${applicant.lastName}`.toLowerCase();
    const email = applicant.email?.toLowerCase();
    const searchValue = searchTerm.toLowerCase();

    return fullName.includes(searchValue) || email.includes(searchValue);
  });

  // Pagination logic
  const indexOfLastApplicant = currentPage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = filteredApplicants.slice(indexOfFirstApplicant, indexOfLastApplicant);
  const totalPages = Math.ceil(filteredApplicants.length / applicantsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="applicants-container">
      <div className="header-container">
        <h1>Rejected Applicants</h1>
        <input
          type="text"
          className="search-bar"
          placeholder="Search rejected applicants..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <table className="applicants-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Resume</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentApplicants.map((applicant) => (
            <tr key={applicant.id}>
              <td>{applicant.firstName}</td>
              <td>{applicant.lastName}</td>
              <td>{applicant.phone}</td>
              <td>{applicant.email}</td>
              <td>
  {applicant.resumeFileURL ? (
    <a
      href={applicant.resumeFileURL}
      target="_blank"
      rel="noopener noreferrer"
      className="resume-link" // Add the class here
    >
      View Resume
    </a>
  ) : (
    'No Resume'
  )}
</td>
              <td>{applicant.status || 'No Status'}</td>
              <td>
                <button
                  className="action-btn"
                  onClick={() => moveToApplicants(applicant)}
                >
                  Move to Applicants
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

      {/* Toast Notifications */}
      <ToastContainer />
    </div>
  );
}

export default Rejected;
