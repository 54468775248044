import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, doc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase'; // Import Firestore instance
import './Applicants.css'; // Custom styling
import { FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa'; // React icons for actions
import ApplicantsCount from './ApplicantsCount'; // Import ApplicantsCount component
import RejectedApplicantsCount from './RejectedApplicantsCount'; // Import RejectedApplicantsCount component
import InterviewsCount from './InterviewsCount';
import HiringCount from './HiringCount';
import { ToastContainer, toast } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

function Applicants() {
  const [applicants, setApplicants] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [editMode, setEditMode] = useState({}); // To track which rows are in edit mode
  const [editedData, setEditedData] = useState({}); // To store edited data
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const applicantsPerPage = 7; // Number of applicants per page
  const [selectedDay, setSelectedDay] = useState(null); // Define selectedDay state

  useEffect(() => {
    // Set up a real-time listener for applicants collection
    const unsubscribe = onSnapshot(collection(db, 'Applicants'), (snapshot) => {
      const applicantsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      // Sort applicants by timestamp in descending order
      const sortedApplicants = applicantsData.sort((a, b) => {
        const aTimestamp = a.timestamp?.toDate ? a.timestamp.toDate().getTime() : 0;
        const bTimestamp = b.timestamp?.toDate ? b.timestamp.toDate().getTime() : 0;
        return bTimestamp - aTimestamp; // Sort in descending order
      });
  
      setApplicants(sortedApplicants);
    });
  
    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate();
      const formattedDate = date.toLocaleDateString('en-US');
      const formattedTime = date.toLocaleTimeString('en-US');
      return `${formattedDate} ${formattedTime}`;
    }
    return 'No Timestamp';
  };

  const toggleExpand = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'Applicants', id));
      toast.success('Applicant deleted successfully!');
    } catch (error) {
      toast.error('Failed to delete applicant.');
    }
  };

  const enableEditMode = (id, applicant) => {
    setEditMode({ ...editMode, [id]: true });
    setEditedData({ ...editedData, [id]: { ...applicant } }); // Initialize edited data for this applicant
  };

  const handleFieldChange = (id, field, value) => {
    setEditedData({
      ...editedData,
      [id]: {
        ...editedData[id],
        [field]: value,
      },
    });
  };

  const saveApplicant = async (id) => {
    try {
      const updatedApplicant = editedData[id];
      if (!updatedApplicant) return;

      await updateDoc(doc(db, 'Applicants', id), {
        firstName: updatedApplicant.firstName || '',
        lastName: updatedApplicant.lastName || '',
        phone: updatedApplicant.phone || '',
        email: updatedApplicant.email || '',
        notes: updatedApplicant.notes || '',
      });

      setApplicants((prevApplicants) =>
        prevApplicants.map((applicant) =>
          applicant.id === id ? { ...applicant, ...updatedApplicant } : applicant
        )
      );

      setEditMode({ ...editMode, [id]: false });
      toast.success('Applicant updated successfully!');
    } catch (error) {
      toast.error('Failed to save applicant details.');
    }
  };

  const saveNotes = async (id) => {
    try {
      const notes = editedData[id]?.notes || '';
      // Update the Firestore document for the specific applicant
      await updateDoc(doc(db, 'Applicants', id), { notes });
  
      // Update only the relevant applicant in the UI
      setApplicants((prevApplicants) => {
        return prevApplicants.map((applicant) => {
          if (applicant.id === id) {
            return { ...applicant, notes }; // Update only this applicant's notes
          }
          return applicant; // Keep the other applicants unchanged
        });
      });
  
      // Collapse the expanded row after saving notes
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
      toast.success('Notes saved successfully!');
    } catch (error) {
      toast.error('Failed to save notes.');
    }
  };

  const moveToContacted = async (applicant) => {
    try {
      await setDoc(doc(db, 'Contacted', applicant.id), {
        ...applicant,
        notes: editedData[applicant.id]?.notes || applicant.notes || '',
      });
      await handleDelete(applicant.id);
      toast.success(`Moved ${applicant.firstName} ${applicant.lastName} to Contacted!`);
    } catch (error) {
      toast.error('Failed to move applicant to Contacted.');
    }
  };

  const moveToRejected = async (applicant) => {
    try {
      await setDoc(doc(db, 'Rejected', applicant.id), applicant);
      await handleDelete(applicant.id);
      toast.success(`Moved ${applicant.firstName} ${applicant.lastName} to Rejected!`);
    } catch (error) {
      toast.error('Failed to move applicant to Rejected.');
    }
  };

  const moveToHired = async (applicant) => {
    try {
      await setDoc(doc(db, 'Hired', applicant.id), applicant);
      await handleDelete(applicant.id);
      toast.success(`Moved ${applicant.firstName} ${applicant.lastName} to Hired!`);
    } catch (error) {
      toast.error('Failed to move applicant to Hired.');
    }
  };

  const indexOfLastApplicant = currentPage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = applicants.slice(indexOfFirstApplicant, indexOfLastApplicant);

  const totalPages = Math.ceil(applicants.length / applicantsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="applicants-container">
      <h1>Review Applicants</h1>

      <div className="counter">
        <ApplicantsCount />
        <RejectedApplicantsCount />
        <InterviewsCount selectedDay={selectedDay} />
        <HiringCount />
      </div>

      <table className="applicants-table">
        <thead>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Resume</th>
            <th>Status</th>
            <th>Timestamp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentApplicants.map((applicant) => (
            <React.Fragment key={applicant.id}>
              <tr>
                <td>
                  <button className="expand-btn" onClick={() => toggleExpand(applicant.id)}>
                    {expandedRows.includes(applicant.id) ? '-' : '+'}
                  </button>
                </td>
                <td>
                  {editMode[applicant.id] ? (
                    <input
                      type="text"
                      value={editedData[applicant.id]?.firstName || ''}
                      onChange={(e) => handleFieldChange(applicant.id, 'firstName', e.target.value)}
                    />
                  ) : (
                    applicant.firstName
                  )}
                </td>
                <td>
                  {editMode[applicant.id] ? (
                    <input
                      type="text"
                      value={editedData[applicant.id]?.lastName || ''}
                      onChange={(e) => handleFieldChange(applicant.id, 'lastName', e.target.value)}
                    />
                  ) : (
                    applicant.lastName
                  )}
                </td>
                <td>
                  {editMode[applicant.id] ? (
                    <input
                      type="text"
                      value={editedData[applicant.id]?.phone || ''}
                      onChange={(e) => handleFieldChange(applicant.id, 'phone', e.target.value)}
                    />
                  ) : (
                    applicant.phone
                  )}
                </td>
                <td>
                  {editMode[applicant.id] ? (
                    <input
                      type="email"
                      value={editedData[applicant.id]?.email || ''}
                      onChange={(e) => handleFieldChange(applicant.id, 'email', e.target.value)}
                    />
                  ) : (
                    applicant.email
                  )}
                </td>
                <td>
                  {applicant.resumeFileURL ? (
                    <a href={applicant.resumeFileURL} target="_blank" rel="noopener noreferrer" className="resume-link">
                      View Resume
                    </a>
                  ) : (
                    'No Resume'
                  )}
                </td>
                <td>{applicant.status || 'No Status'}</td>
                <td>{formatTimestamp(applicant.timestamp)}</td>
                <td className="actions-container">
                  {editMode[applicant.id] ? (
                    <FaCheck
                      className="action-icon row-edit-icon"
                      onClick={() => saveApplicant(applicant.id)}
                      title="Save"
                    />
                  ) : (
                    <FaEdit
                      className="action-icon row-edit-icon"
                      onClick={() => enableEditMode(applicant.id, applicant)}
                      title="Edit"
                    />
                  )}
                  <FaCheck
                    className="action-icon accept-icon"
                    onClick={() => moveToContacted(applicant)}
                    title="Contact"
                  />
                  <FaTimes
                    className="action-icon reject-icon"
                    onClick={() => moveToRejected(applicant)}
                    title="Reject"
                  />
                </td>
              </tr>
              {expandedRows.includes(applicant.id) && (
                <tr className="expandable-row">
                  <td colSpan="9">
                    <div className="notes-section">
                      <h4>Notes for {applicant.firstName}</h4>
                      <textarea
                        rows="4"
                        value={
                          editedData[applicant.id]?.notes !== undefined
                            ? editedData[applicant.id].notes
                            : applicant.notes || ''
                        }
                        onChange={(e) => handleFieldChange(applicant.id, 'notes', e.target.value)}
                        placeholder="Add notes here..."
                      ></textarea>
                      <button className="save-notes-btn" onClick={() => saveNotes(applicant.id)}>
                        Save
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Applicants;
