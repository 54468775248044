import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase'; // Firestore instance
import { FaEnvelope, FaTimes } from 'react-icons/fa'; // Icons for actions
import EmailModal from './EmailModal'; // Import the modal component

function Hiring() {
  const [hiring, setHiring] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [selectedApplicantEmail, setSelectedApplicantEmail] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const applicantsPerPage = 5;

  useEffect(() => {
    // Fetch data from Firestore (Hiring collection)
    const fetchHiring = async () => {
      const querySnapshot = await getDocs(collection(db, 'Hiring'));
      const hiringData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setHiring(hiringData);
    };

    fetchHiring();
  }, []);

  // Pagination logic
  const indexOfLastApplicant = currentPage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentHiring = hiring.slice(indexOfFirstApplicant, indexOfLastApplicant);

  const totalPages = Math.ceil(hiring.length / applicantsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  // Move applicant to Rejected collection
  const moveToRejected = async (applicant) => {
    await setDoc(doc(db, 'Rejected', applicant.id), applicant);
    await deleteDoc(doc(db, 'Hiring', applicant.id));
    setHiring(hiring.filter((item) => item.id !== applicant.id));
  };

  // Open the email modal
  const openEmailModal = (email) => {
    setSelectedApplicantEmail(email);
    setIsEmailModalOpen(true);
  };

  // Handle expand/collapse for the row
  const toggleExpand = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);

      const applicant = hiring.find((applicant) => applicant.id === id);
      if (applicant) {
        setEditedData({
          ...editedData,
          [id]: {
            notes: applicant.notes || '',
          },
        });
      }
    }
  };

  // Handle field changes in edit mode for notes
  const handleFieldChange = (e, id) => {
    setEditedData({
      ...editedData,
      [id]: {
        ...editedData[id],
        notes: e.target.value,
      },
    });
  };

  // Save notes to Firestore
  const saveNotes = async (id) => {
    const updatedNotes = editedData[id]?.notes || '';
    await updateDoc(doc(db, 'Hiring', id), {
      notes: updatedNotes,
    });

    setHiring((prevHiring) =>
      prevHiring.map((applicant) =>
        applicant.id === id ? { ...applicant, notes: updatedNotes } : applicant
      )
    );
    setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
  };

  return (
    <div className="applicants-container">
      <h1>Hiring Applicants</h1>

      <table className="applicants-table">
        <thead>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Resume</th>
            <th>Status</th>
            <th>Timestamp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentHiring.map((applicant) => (
            <React.Fragment key={applicant.id}>
              <tr>
                <td>
                  <button
                    className="expand-btn"
                    onClick={() => toggleExpand(applicant.id)}
                  >
                    {expandedRows.includes(applicant.id) ? '-' : '+'}
                  </button>
                </td>
                <td>{applicant.firstName}</td>
                <td>{applicant.lastName}</td>
                <td>{applicant.phone}</td>
                <td>{applicant.email}</td>
                <td>
                  {applicant.resumeFileURL ? (
                    <a
                      href={applicant.resumeFileURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="resume-link"
                    >
                      View Resume
                    </a>
                  ) : (
                    'No Resume'
                  )}
                </td>
                <td>{applicant.status || 'No Status'}</td>
                <td>
                  {applicant.timestamp
                    ? new Date(applicant.timestamp.seconds * 1000).toLocaleDateString()
                    : 'No Timestamp'}
                </td>
                <td className="actions-container">
                  <button
                    title="Send Email"
                    className="action-icon send-mail"
                    onClick={() => openEmailModal(applicant.email)}
                  >
                    <FaEnvelope />
                  </button>
                  <button
                    title="Reject Applicant"
                    className="action-icon reject-btn"
                    onClick={() => moveToRejected(applicant)}
                  >
                    <FaTimes />
                  </button>
                </td>
              </tr>

              {/* Expandable row for notes */}
              {expandedRows.includes(applicant.id) && (
                <tr className="expandable-row">
                  <td colSpan="9">
                    <div className="notes-section">
                      <h4>Notes for {applicant.firstName}</h4>
                      <textarea
                        rows="4"
                        value={editedData[applicant.id]?.notes || ''}
                        onChange={(e) => handleFieldChange(e, applicant.id)}
                        placeholder="Add notes here..."
                      ></textarea>
                      <button className="save-notes-btn" onClick={() => saveNotes(applicant.id)}>
                        Save
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

      {/* Email Modal */}
      <EmailModal
        isOpen={isEmailModalOpen}
        onRequestClose={() => setIsEmailModalOpen(false)}
        applicantEmail={selectedApplicantEmail}
      />
    </div>
  );
}

export default Hiring;
