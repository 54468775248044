import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase'; // Import Firebase instance
import './RejectedApplicantsCount.css'; // Reuse the same styles

function RejectedApplicantsCount() {
  const [rejectedCount, setRejectedCount] = useState(0);

  useEffect(() => {
    // Fetch total count of documents from the "Rejected" collection in Firebase
    const fetchRejectedCount = async () => {
      const querySnapshot = await getDocs(collection(db, 'Rejected'));
      setRejectedCount(querySnapshot.size); // Use size to get the number of documents
    };

    fetchRejectedCount();
  }, []);

  return (
    <div className="Rejected-count-container">
      <h2>Rejected Applications</h2>
      <div className="count-number">{rejectedCount}</div>
    </div>
  );
}

export default RejectedApplicantsCount;
