import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBECbFy8lInv1xe82UNt_CkR9Vr21mdE-s",
    authDomain: "hiring-dashboard-1b3bf.firebaseapp.com",
    projectId: "hiring-dashboard-1b3bf",
    storageBucket: "hiring-dashboard-1b3bf.appspot.com",
    messagingSenderId: "661661114072",
    appId: "1:661661114072:web:59cf0222253af8342d41ad",
    measurementId: "G-F1LQ6P0RFL"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app); // Initialize Firebase Auth

export { db, auth };