import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase'; // Import Firebase instance
import './HiringCount.css'; // Custom styles for the graph

function HiringCount() {
  const [hiringCount, setHiringCount] = useState(0);

  useEffect(() => {
    // Fetch total count of records in the Hiring collection
    const fetchHiringCount = async () => {
      const querySnapshot = await getDocs(collection(db, 'Hiring'));
      setHiringCount(querySnapshot.size); // Use size to get the number of documents
    };

    fetchHiringCount();
  }, []);

  return (
    <div className="hiring-count-container">
      <h2>Hiring Applicants</h2>
      <div className="count-number">{hiringCount}</div>
    </div>
  );
}

export default HiringCount;
