import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase'; // Import Firebase instance
import './InterviewsCount.css'; // Custom styles for the graph

function InterviewsCount() {
  const [interviewCount, setInterviewCount] = useState(0);
  const today = new Date(); // Use today's date

  useEffect(() => {
    // Fetch total count of interviews from Firebase for the current day
    const fetchInterviewsCount = async () => {
      const querySnapshot = await getDocs(collection(db, 'ScheduledInterviews'));
      const interviews = querySnapshot.docs.filter((doc) => {
        const interviewDate = new Date(doc.data().interviewDate.seconds * 1000);
        return interviewDate.toLocaleDateString() === today.toLocaleDateString(); // Match the date
      });
      setInterviewCount(interviews.length); // Set the count of interviews
    };

    fetchInterviewsCount();
  }, []);

  return (
    <div className="interviews-count-container">
      <h2>Today's Interviews</h2>
      <div className="count-number">{interviewCount}</div>
    </div>
  );
}

export default InterviewsCount;
