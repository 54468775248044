import React, { useState } from 'react';
import Modal from 'react-modal';
import emailjs from 'emailjs-com'; // Import emailjs
import './EmailModal.css';

const EmailModal = ({ isOpen, onRequestClose, applicantEmail, applicantFirstName }) => {
  const [selectedTemplate, setSelectedTemplate] = useState('template_o3doxne');
  const [message, setMessage] = useState('');

  const templates = [
    { id: 'template_o3doxne', name: 'Interview Request' },
  ];

  const handleSend = () => {
    const templateParams = {
      email: applicantEmail, // Applicant email (maps to {{email}})
      to_name: applicantFirstName, // Applicant's first name (maps to {{to_name}})
      message: message, // Optional message body or notes to include
    };

    // Send the email using the selected template
    emailjs
      .send('service_7ew92fl', selectedTemplate, templateParams, 'DnstedrGQQqbvY1vY')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
      })
      .catch((error) => {
        console.error('Failed to send the email:', error);
      });

    onRequestClose(); // Close the modal after sending the email
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Send Email"
      className="email-modal"
    >
      <h2>Send Email</h2>
      <div className="modal-content">
        <label>To:</label>
        <input type="email" value={applicantEmail} readOnly />

        <label>First Name:</label>
        <input type="text" value={applicantFirstName} readOnly /> {/* Display First Name */}

        <label>Select Email Template:</label>
        <select
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
        >
          {templates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>

        <label>Additional Message (Optional):</label>
        <textarea
          rows="5"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="You can add additional information here..."
        ></textarea>

        <div className="modal-actions">
          <button onClick={handleSend}>Send Email</button>
          <button onClick={onRequestClose}>Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default EmailModal;
