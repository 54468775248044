import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './Layout';
import Applicants from './Applicants';
import Contacted from './Contacted';
import Rejected from './Rejected';
import Hiring from './Hiring';
import SignIn from './SignIn'; // Import the SignIn component
import PrivateRoute from './PrivateRoute';
import { ToastContainer } from 'react-toastify'; // Import Toastify
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            <Route index element={<Navigate to="applicants" />} />
            <Route path="applicants" element={<Applicants />} />
            <Route path="contacted" element={<Contacted />} />
            <Route path="hiring" element={<Hiring />} />
            <Route path="rejected" element={<Rejected />} />
          </Route>
        </Routes>
      </Router>
      
      {/* Toast Notification Container */}
      <ToastContainer />
    </>
  );
}

export default App;
