import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './SignIn.css';
import Spinner from './Spinner'; // Import the spinner component

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show the spinner when the login process starts
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        // Introduce a delay before navigating to /applicants
        setTimeout(() => {
          navigate('/applicants');
        }, 2000); // Delay for 2 seconds before redirecting
      }
    } catch (error) {
      setError(error.message); // Show error message if login fails
      setLoading(false); // Stop showing the spinner if there is an error
    }
  };

  return (
    <div className="sign-in-page">
      {loading && <Spinner />} {/* Show spinner while loading */}
      <div className="sign-in-container">
        <div className="logo-container">
          <img src="/assets/logo.png" alt="Logo" className="logo" />
        </div>
        <h2>Sign In</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>Sign In</button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
