import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore'; // Use onSnapshot for real-time updates
import { db } from './firebase'; // Import Firebase instance
import './ApplicantsCount.css'; // Custom styles for the graph

function ApplicantsCount() {
  const [applicantCount, setApplicantCount] = useState(0);

  useEffect(() => {
    // Real-time listener for the count of applicants
    const unsubscribe = onSnapshot(collection(db, 'Applicants'), (snapshot) => {
      setApplicantCount(snapshot.size); // Use size to get the number of documents
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <div className="applicants-count-container">
      <h2>New Applications</h2>
      <div className="count-number">{applicantCount}</div>
    </div>
  );
}

export default ApplicantsCount;
